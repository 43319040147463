<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="姓名，订单号"
          ></el-input>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            placeholder="类型"
            v-model="form_2.gradeType"
            size="medium"
          >
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="企业" value="enterprise"></el-option>
            <el-option label="个人 " value="user"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            placeholder="状态"
            v-model="form_2.tradeStatus"
            size="medium"
          >
            <el-option label="全部" value="-1"></el-option>
            <el-option label="等待支付" :value="0"></el-option>
            <el-option label="支付成功 " :value="1"></el-option>
            <el-option label="订单关闭 " :value="2"></el-option>
          </el-select>
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >创建时间：</label
        >
        <nav class="input" style="width: 250px;">
          <el-date-picker
            v-model="form_2.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-button @click="reset" size="small">重置</el-button>
          <el-button @click="subimt" type="primary" size="small">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="realName" label="姓名">
            <template slot-scope="{ row }">
                {{row.realName||row.nickname}}
            </template>
        </el-table-column>
        <el-table-column prop="gradeType" label="会员类型">
          <template slot-scope="{ row }">
            {{ row.gradeType == 'user' ? '个人' : '企业' }}
          </template>
        </el-table-column>
        <el-table-column prop="gradeName" label="会员等级"> </el-table-column>
        <el-table-column prop="payType" label="支付类型"> </el-table-column>
        <el-table-column prop="receiveUser" label="订单号">
          <template slot-scope="{ row }">
            <p>本站：{{ row.tradeNo }}</p>
            <p>支付平台：{{ row.outTradeNo }}</p>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="endTimeType" label="合作截至时间">
          <template slot-scope="{ row }">
            <p>{{ row.endTimeType ? '长期有效' : row.endTime }}</p>
          </template>
        </el-table-column> -->
        <el-table-column prop="terminal" label="金额">
          <template slot-scope="{ row }">
            <p>用户订单金额：￥{{ row.orderAmount }}</p>
            <p>用户实际支付金额：￥{{ row.payAmount }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="terminal" label="付款终端"> </el-table-column>
        <el-table-column prop="terminal" label="状态">
          <template slot-scope="{ row }">
            <el-tag type="warning" size="small" class="box">{{
              show_status(row.tradeStatus)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="terminal" label="开票">
          <template slot-scope="{ row }">
            <el-tag
              :type="row.invoiceStatus == 1?'':'warning'"
              size="small"
              class="box"
              style="cursor: pointer;"
              @click="Oninvoice(row, row.invoiceStatus)"
              >{{ show_invoice(row.invoiceStatus) }}</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column prop="sendTime" label="时间">
          <template slot-scope="{ row }">
            <p>创建时间：{{ row.createTime }}</p>
            <p>支付时间：{{ row.payTime }}</p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <invoice ref="invoice" @getlist="getlist"></invoice>
  </div>
</template>

<script>
let that
import rules from '../../utils/rules'
import show from '../../components/countDowns/show.vue'
// import mixins from '../../mixin/getdiction'
import invoice from './invoice.vue'
export default {
  // mixins: [mixins],
  beforeCreate() {
    that = this
  },
  data() {
    return {
      title: '',
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      confing: '',
      btn: true,
      action: {
        action: 'enterprise'
      },
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      proof: false,
      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        gradeType: '',
        tradeStatus: '',
        createTime: []
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      s_form: {
        page: 1,
        pageSize: 10,
        keyword: '',
        gradeType: '',
        tradeStatus: '',
        createTime: []
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      form_show: {},
      area: []
    }
  },
  updated() {
    //   console.log(this.form);
  },
  components: {
    Show: show,
    invoice
  },
  methods: {
    reset() {
      this.form_2 = {
        page: 1,
        pageSize: 10,
        keyword: '',
        gradeType: '',
        tradeStatus: '',
        createTime: []
        // order: ''
      }
      this.s_form = {
        ...this.form_2
      }
      this.getlist()
    },
    subimt() {
      this.currentPage = 1
      this.form_2.page = 1
      this.s_form = {
        ...this.form_2
      }
      this.getlist()
    },
    show_type(val) {
      if (val == 1) {
        return '提供服务'
      } else {
        return '需要资源'
      }
    },
    check() {
      console.log(1)
    },
    async Oninvoice(row, val) {
      if (this.$store.state.btn_if.indexOf('order_invoice') == -1) {
        this.$message.error('你没有开票权限★')
        return
      }
      // console.log());
      if (val == 0) {
        this.$message.error('你还没有申请')
        return
      }
      if (row.invoiceInfo.length > 0) {
        var info = JSON.parse(row.invoiceInfo)
        if (!info.invoiceLocation) {
          info = {
            ...info,
            invoiceLocation: row.invoiceLocation
          }
        }
        this.$refs.invoice.form = {
          ...info,
          id: row.id
        }
        // if(val==2){
        //   this.
        // }
        this.$refs.invoice.status = val == 2 ? false : true
        this.$refs.invoice.dialogVisible = true
      }
    },
    information(row) {
      this.showinfo = true
      // this.form_show = row
      this.$http
        .get('/admin/UserSpeaker/getById', {
          params: {
            id: row.id
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_show = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    show_invoice(row) {
      if (row == 0) {
        return '未申请'
      } else if (row == 1) {
        return '发票申请中'
      } else if (row == 2) {
        return '已开票'
      }
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else if (c.prop == 'views') {
          s = 'report_views'
        } else {
          s = 'report_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },

    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      // this.getlist()

      this.s_form.page = e

      this.getlist(this.s_form)
    },
    getlist(val) {
      var form = val ? val : this.form_2
      this.$http
        .get('/admin/UserOrder/getList', {
          params: {
            ...form,
            createTime: form.createTime.join('~'),
            gradeType: form.gradeType == -1 ? '' : form.gradeType
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res)
            this.list = res.data.list
            // console.log(this.list)
            this.total = res.data.totalCount
          } else {
            this.$message.error(res.message)
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    async beforedown() {
      const phone = this.$cookies.get('phone')
      if (!!!phone) {
        this.$message.error('没有绑定手机号')
        return
      }
      if (!this.proof) {
        this.dioA = true
      } else {
        this.download()
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {
        //       ...this.form_3
        //     }
        //   }
        // )
        // this.btn = true
        // // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          const { data: res } = await this.$http.get(
            '/admin/UserSpeaker/exportExcel',
            {
              params: {
                ...this.form_2,
                ...this.form_3
              }
            }
          )
          this.proof = true
          this.btn = true
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.href = res.data.url
            this.dioA = false
          } else {
            this.$message.error(res.message)
          }
        }
      }
    },
    add() {},
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },

    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // sessionStorage.setItem('flag', false)
      // this.p_a = true
      this.$router.push({
        name: 'cooperation_list_add',
        params: {
          id
        }
      })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/cooperation/del', {
              params: {
                id: id
              }
            })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    setTop(row, val) {
      // console.log(1)
      // console.log(row, id)
      this.$http
        .post('/admin/cooperation/top', {
          id: row.id,
          top: val
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              customClass: 'zZindex'
            })
          }
          // this.btn1 = true
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/Cooperation/hide', {
          id: id,
          isHide: el
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    chagne(row) {
      this.$http
        .post('/admin/UserSpeaker/editService', {
          id: row.id,
          waId: row.waId.join(',')
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.getlist()
          } else {
            this.$message.error(res.message)
            this.getlist()
          }
        })
    }
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.getlist()
    sessionStorage.setItem('action', 'cooperation')
  },
  mounted() {
    console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
  },
  computed: {
    show_status() {
      return val => {
        var key
        switch (val) {
          case 0:
            key = '等待支付'
            break
          case 1:
            key = '支付成功'
            break
          case 2:
            key = '未付款交易超时关闭'
            break
        }
        return key
      }
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;

  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.skyblue {
  color: skyblue;
}

.green {
  color: green;
}

.yellow {
  color: pink;
}

.red {
  color: red;
}

.out .input {
  width: 18%;
}

.el-card {
  position: relative;
}

.out .right {
  float: right;
}

.box {
  display: inline-block;
  margin: 5px;
}
</style>
