<template>
  <div>
    <el-dialog title="电子发票" :visible.sync="dialogVisible" width="34%">
      <el-form ref="formref" :model="form" label-width="120px">
        <el-form-item
          label="发票种类"
          prop="purchaserId"
          v-show="form.invoiceKind"
        >
          <span>{{
            form.invoiceKind == 'paper' ? '纸质发票' : '电子发票'
          }}</span>
        </el-form-item>
        <el-form-item
          label="抬头类型"
          prop="purchaserId"
          v-show="form.invoiceTitleType"
        >
          <span>{{ f_individual(form.invoiceTitleType) }}</span>
        </el-form-item>
        <el-form-item
          label="发票类型"
          prop="purchaserId"
          v-show="form.invoiceType"
        >
          <span>{{ form.invoiceType=='tax_invoice'?'增值税普通发票':'增值税专用发票'}}</span>
        </el-form-item>
        <el-form-item
          label="纳税人识别号"
          prop="purchaserId"
          v-show="form.purchaserId"
        >
          <span>{{ form.purchaserId }}</span>
        </el-form-item>
        <el-form-item
          label="基本开户银行"
          prop="purchaserBankName"
          v-show="form.purchaserBankName"
        >
          <!-- <el-input v-model="form.purchaserBankName"></el-input> -->
          <span>{{ form.purchaserBankName }}</span>
        </el-form-item>
        <el-form-item
          label="基本开户账号"
          prop="purchaserBankAccount"
          v-show="form.purchaserBankAccount"
        >
          <!-- <el-input v-model="form.purchaserBankAccount"></el-input> -->
          <span>{{ form.purchaserBankAccount }}</span>
        </el-form-item>
        <el-form-item
          label="企业注册地址"
          prop="purchaserAddress"
          v-show="form.purchaserAddress"
        >
          {{ form.purchaserAddress }}
        </el-form-item>
        <el-form-item
          label="企业注册电话"
          prop="purchaserPhone"
          v-show="form.purchaserPhone"
        >
          <!-- <el-input v-model="form.purchaserPhone"></el-input> -->
          <span>{{ form.purchaserPhone }}</span>
        </el-form-item>
        <el-form-item
          label="联系人姓名"
          prop="expressReceiver"
          v-show="form.expressReceiver"
        >
          {{ form.expressReceiver }}
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="expressPhone"
          v-show="form.expressPhone"
        >
          {{ form.expressPhone }}
        </el-form-item>
        <el-form-item
          label="联系地址"
          prop="expressAddress"
          v-show="form.expressAddress"
        >
          {{ form.expressAddress }}
        </el-form-item>
        <el-form-item
          :label="form.invoiceKind == 'paper' ? '物流单号' : '电子发票URL'"
          prop="invoiceLocation"
        >
          <el-input v-model="form.invoiceLocation"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-show="status">
        <el-button type="primary" @click="onSubmit">确认开票</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  watch: {
    form: {
      handler(val, oldVal) {
        // console.log(val)
      },
      deep: true //true 深度监听
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        purchaserId: '',
        invoiceLocation: ''
      },
      status: false
    }
  },
  methods: {
    async onSubmit() {
      let { data: res } = await this.$http.post('/admin/UserOrder/outInvoice', {
        invoiceLocation: this.form.invoiceLocation,
        id: this.form.id
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.dialogVisible = false
        this.$emit('getlist')
      } else {
        this.$message.error(res.message)
      }
    },
    f_individual(val) {
      if (val == 'individual') {
        return '个人'
      } else if (val == 'enterprise') {
        return '企业'
      } else if (val == 'organization') {
        return '组织'
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
